<template>
<div>
    <page-header :title="`Chỉnh sửa lĩnh vực thông tin`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="12">
                <b-card>
                    <b-form-group>
                        <label>Tên lĩnh vực <span class="red">*</span></label>
                        <b-input v-model="form.name" placeholder="Nhập tên lĩnh vực" />
                    </b-form-group>
                    <b-form-group>
                        <label>Thứ tự</label>
                        <b-input type="number" v-model.number="form.order" min="1" max="50" placeholder="Nhập thứ tự"/>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import {
    httpClient
} from "../../../_utils/httpClient";

import _ from "lodash";
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        PageHeader
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thiết lập lĩnh vực quyết định',
                to: '/admin/config-lvtt',
            }, {
                text: 'Chỉnh sửa',
                to: '#',
                active: true,
            }, ],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                name: '',
                order: 1
            },
            loading: false,
        };
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.config.lvtt'
            })
        },
        validator() {
            if (this.form.name == null || this.form.name == "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Tên lĩnh vực không được trống.`
                return false
            }
            return true
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            if(!this.form.order) this.form.order = 0;
            let payload = {
                ...this.form,
                id: this.form.id
            }
            await Swal.queue([{
                title: 'Chỉnh sửa lĩnh vực thông tin này?',
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`config`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Chỉnh sửa lĩnh vực thông tin thành công',
                        });
                        await this.toListView()
                    } else {
                        Swal.insertQueueStep({
                            title: 'Chỉnh sửa lĩnh vực thông tin không thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                },
            },]);
        },
        async getById(id) {
            let response = await httpClient.get(`config`, {
                params: {
                    id,
                }
            })
            return response.data.data
        },
    },
    async created() {
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id);
        }
        this.form = {
            ...cloneItem
        }
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}
</style>
<style>
.red {
    color: red;
}
</style>